<template>
  <div class="ratePay-detail">
    <div class="banner">
      <img src="../assets/images/nashuiketang.jpg" alt="" width="100%">
    </div>
    <div class="content">
      <div class="title">{{result.title}}</div>
      <el-divider></el-divider>
      <div class="detail" v-html="result.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ratePayCourseDetail',
  props: ['id'],
  data () {
    return {
      result: {}
    }
  },
  methods: {
    getData () {
      this.API.getArticledetail({ id: this.id }).then(({ code, data }) => {
        this.result = data
      })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.content .detail {
  width: 1200px;
  margin: 20px  auto;
  text-align: left;
}
.title{
  color: #323534;
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 10px;
}
</style>
